import { environment } from "src/environments/environment";

export const websiteUrl = environment.websiteUrl;

export const apiUrl = environment.apiUrl;

//session APIs
export const loginUrl: string = "/auth/login";

export const logoutUrl: string = "/auth/logout";

export const signupUrl: string = "/auth/signup";

//dashboard APIs
export const statisticsUrl: string = "/dashboard";

//project APIs
export const projectsListUrl: string = "/project/index";

export const newProjectUrl: string = "/project/create";

export const projectDetailsUrl: string = "/project/show";

export const updateProjectUrl: string = "/project/update";

export const getProjectByLink: string = "/project/id";

export const deleteProjectUrl: string = "/project/delete";

//Phrases APIs
export const phrasesUrl: string = "/project/phrases";

export const updatePhraseUrl: string = "/phrase/edit";

export const deletePhraseUrl: string = "/phrase/delete";

export const addPhraseUrl: string = "/phrase/add";

//Records APIs
export const getAllRecordsUrl: string = "/project/filter";

export const downloadAllRecordsUrl: string = "/record/download/all";

export const downloadSelectedRecordsUrl: string = "/record/download/selected";

export const downloadRecordsByPhraseUrl: string = "/record/download/phrase";

export const downloadRecordsByUserProjectUrl: string = "/record/download/userV2";

export const deleteRecordsUrl: string = "/record/delete";

export const uploadRecordUrl: string = "/record/upload";

export const submitRecordsUrl: string = "/record/sendEmail";

export const downloadSingleRecord: string = "/record/download/single";

export const rejectRecordUrl: string = "/record/reject";

export const downloadRejectedUrl: string = "/record/download/rejected";

//User API
export const userInfoUrl: string = "/user/show";

export const usersListUrl: string = "/user/indexV2";

export const allUsersUrl: string = "/users";

// Reviewers API
export const addReviewerUrl: string = "/reviewer/add";

export const deleteReviewerUrl: string = "/reviewer/delete";

// Users Operations API
export const getUserListOperationsUrl: string = "/user-operation";
