import { Injectable } from '@angular/core';

//Object to mask the key-value pair of (isAdmin, 1)
//to store it in localstorage and to be unknown to the user
const cryptoRandom = {
  role: 'wsulmfnd',
  admin: 'iqxhbpjb',
  reviewer: 'jtwjgvig',
  user: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10)
}

@Injectable({
  providedIn: 'root'
})
export class RoleAuthService {

  constructor() { }

  handle(user) {
    this.setRole(user);
  }

  setRole(user) {
    let value: string;
    switch (user.role_id) {
      case 1:
        value = cryptoRandom.admin;
        break;
      case 2:
        value = cryptoRandom.reviewer;
        break;
      case 3:
        value = cryptoRandom.user;
        break;
    }
    localStorage.setItem(cryptoRandom.role, value);
  }

  get() {
    return localStorage.getItem(cryptoRandom.role);
  }

  remove() {
    localStorage.removeItem(cryptoRandom.role);
  }

  isAdmin() {
    const role = this.get();
    if (role && role == cryptoRandom.admin) {
      return true;
    }
    return false;
  }

  isReviewer() {
    const role = this.get();
    if (role && role == cryptoRandom.reviewer) {
      return true;
    }
    return false;
  }

}
