import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ShareableLinkResolverService {

  constructor(
    private router: Router,

  ) {}
 
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {

    let projectLink = route.paramMap.get('projectLink');
    
    localStorage.setItem('projectLink', projectLink);

    this.router.navigateByUrl('/user');
    
    return EMPTY;
  }
}
