import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGaurd } from './auth/auth.gaurd';
import { AdminAuthGaurd } from './admin/layout/admin-auth.gaurd';
import { ShareableLinkResolverService } from './services/resolvers/shareable-link/shareable-link-resolver.service';
import { ReviewerAuthGaurd } from './reviewer/layout/reviewer-auth.gaurd';


const routes: Routes = [
  {
    path: 'session',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin',
    canLoad: [AuthGaurd, AdminAuthGaurd],
    canActivate: [AuthGaurd, AdminAuthGaurd],
    loadChildren: () => import('./admin/layout/admin-layout.module').then(m => m.AdminLayoutsModule)
  },
  {
    path: 'reviewer',
    canLoad: [AuthGaurd, ReviewerAuthGaurd],
    canActivate: [AuthGaurd, ReviewerAuthGaurd],
    loadChildren: () => import('./reviewer/layout/reviewer-layout.module').then(m => m.ReviewerLayoutsModule)
  },
  {
    path: 'user',
    canLoad: [AuthGaurd],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'sh/:projectLink',
    resolve: {
      projectLink: ShareableLinkResolverService 
    },
    loadChildren: () => import('./others/others.module').then(m => m.OthersModule)
  },
  {
    path: '',
    redirectTo: 'admin',
    pathMatch: 'full'
  },
  {
    path: '**',
    loadChildren: () => import('./others/others.module').then(m => m.OthersModule)
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
