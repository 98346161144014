import { Injectable } from '@angular/core';
import { Router, CanLoad, CanActivate } from '@angular/router';
import { RoleAuthService } from 'src/app/auth/services/admin-auth.service';


@Injectable({
  providedIn: 'root'
})
export class AdminAuthGaurd implements CanLoad, CanActivate {

  constructor(
    private router: Router,
    private roleAuth: RoleAuthService
  ) {}

  canLoad() {
    const isReviewer = this.roleAuth.isReviewer();
    const isAdmin = this.roleAuth.isAdmin();
    if (isAdmin) {
      return true;
    } else if (isReviewer) {
      this.router.navigateByUrl('/reviewer');
      return false;
    } else {
      this.router.navigateByUrl('/user');
      return false;
    }
  }

  canActivate() {
    const isReviewer = this.roleAuth.isReviewer();
    const isAdmin = this.roleAuth.isAdmin();
    if (isAdmin) {
      return true;
    } else if (isReviewer) {
      this.router.navigateByUrl('/reviewer');
      return false;
    } else {
      this.router.navigateByUrl('/user');
      return false;
    }
  }

}
