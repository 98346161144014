import { Injectable } from '@angular/core';
import { Router, CanActivateChild, CanLoad, CanActivate } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanLoad, CanActivate {

  isLoggedIn: boolean;
  
  constructor(
    private router: Router,
    private auth: AuthService
  ) {
    this.auth.authStatus$.subscribe(authstat => this.isLoggedIn = authstat);
  }

  canLoad() {
    if (this.isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/session']);
      return false;
    }
  }

  canActivate() {
    if (this.isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/session']);
      return false;
    }
  }
}
