import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl, loginUrl, signupUrl, logoutUrl } from '../../services/_api';
import { TokenService } from './token.service';
import { tap, map } from 'rxjs/operators';
import { RoleAuthService } from './admin-auth.service';

//Object to mask the key-value pair of (isAdmin, 1)
//to store it in localstorage and to be unknown to the user
const cryptoRandom = {
  isAdmin: 'wsulmfnd',
  one: 'iqxhbpjb',
  zero: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10)
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private roleAuth: RoleAuthService,
    private router: Router
  ) { }

  signup(data) {
    return this.http.post<any>(apiUrl + signupUrl, data);
  }

  login(data) {
    return this.http.post<any>(apiUrl + loginUrl, data);
  }

  signout() {
    localStorage.clear();
    this.changeAuthStatus(false);
    this.refreshAdminStatus();
    this.refreshReviewerStatus();
    this.router.navigateByUrl('/session');
    return this.http.get<any>(apiUrl + logoutUrl);
  }

  private loggedIn = new BehaviorSubject<boolean>(this.token.loggedIn());
  authStatus$ = this.loggedIn.asObservable();

  changeAuthStatus(value: boolean) {
    this.loggedIn.next(value);
  }

  private admin = new BehaviorSubject<boolean>(this.roleAuth.isAdmin());
  adminStatus$ = this.admin.asObservable();

  refreshAdminStatus() {
    this.admin.next(this.roleAuth.isAdmin());
  }

  private reviewer = new BehaviorSubject<boolean>(this.roleAuth.isReviewer());
  reviewerStatus$ = this.reviewer.asObservable();

  refreshReviewerStatus() {
    this.admin.next(this.roleAuth.isReviewer());
  }
}
