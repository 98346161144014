import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { apiUrl, newProjectUrl, uploadRecordUrl } from '../services/_api';

//APIs with upload files
const toNotAddHeader = [
    apiUrl + newProjectUrl,
    apiUrl + uploadRecordUrl
]

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(
        public toastr: ToastrService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token: string = localStorage.getItem('token');

        if (token && (request.url.indexOf(apiUrl) != -1) ) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if (!request.headers.has('Content-Type') && !toNotAddHeader.includes(request.url) ) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {

                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let reqAndErr = {
                    request: request,
                    error: error
                };
                
                if(error.status >= 400 && error.status < 500) {
                    if(error.status == 401 || error.error.message == "Unauthorized") {
                        this.toastr.error("wrong email or password", "Failed");
                    } else {
                        this.toastr.error(error.error.message, "Failed");
                    }
                }
                return throwError(reqAndErr);
            }));
    }
}